import "./App.css";
import Navbar from "./components/Navbar"
import Pdf from "./components/Pdf"; 

function App() {
  return (
    <div className="App">
      <Navbar />
      <Pdf />
    </div>
  );
}

export default App;
