import React from "react";

function Navbar() {
  return (
    <div className="navbar">
      <div>
        <a href="https://ctlcomms.com/">
        <img
          src="https://ctlcomms.com/wp-content/uploads/2021/07/Asset-1.png"
          alt="CTL Communications logo"
        />
        </a>
      </div>
      <div className="navLinks">
          <a href="https://ctlcomms.com/#campaigns-that-cut-through">What we do</a>
          <a href="https://ctlcomms.com/#for-us-its-personal">Who we are</a>
          <a href="mailto:hello@ctlcomms.com?subject=I’d like to find out more">Get in touch</a>
      </div>
    </div>
  );
}

export default Navbar;
